export const LOGIN_SUCCESS = Symbol("LOGIN_SUCCESS")
export const LOGIN_ERROR = Symbol("LOGIN_SUCCESS")
export const GET_VEHICLES_SUCCESS = Symbol("GET_VEHICLE_SUCCESS")
export const GET_VEHICLES_ERROR = Symbol("GET_VEHICLE_ERROR")
export const GET_ROUTES_SUCCESS = Symbol("GET_ROUTES_SUCCESS")
export const GET_ROUTES_ERROR = Symbol("GET_ROUTES_ERROR")
export const GET_TRIPS_SUCCESS = Symbol("GET_TRIPS_SUCCESS")
export const GET_TRIPS_ERROR = Symbol("GET_TRIPS_ERROR")
export const ADD_VEHICLE_SUCCESS = Symbol("ADD_VEHICLE_SUCCESS")
export const ADD_VEHICLE_ERROR = Symbol("ADD_VEHICLE_ERROR")
export const ADD_TRIP_SUCCESS = Symbol("ADD_ROUTE_SUCCESS")
export const ADD_TRIP_ERROR = Symbol("ADD_TRIP_ERROR")
export const GET_STAFF_SUCCESS = Symbol("GET_STAFF_SUCCESS")
export const GET_STAFF_ERROR = Symbol("GET_STAFF_ERROR")
export const GET_TICKETS_SUCCESS = Symbol("GET_TICKETS_SUCCESS")
export const GET_TICKETS_ERROR = Symbol("GET_TICKETS_ERROR")
export const GET_TRANSACTIONS_SUCCESS = Symbol("GET_TRANSACTIONS_SUCCESS")
export const GET_TRANSACTIONS_ERROR = Symbol("GET_TRANSACTIONS_ERROR")
